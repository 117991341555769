import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/main-menu.css';

const MainMenu: React.FC = () => {
    const navigate = useNavigate();

    // Wrap options in useMemo so that it doesn't change on every render
    const options = useMemo(() => [
        { label: "BATTLE!", action: "startBattle" },
        { label: "STORY MODE", action: "displayStoryMode" },
        { label: "LOG OUT", action: "logout" },
        { label: "SETTINGS", action: "displaySettings" }
    ], []);

    const handleOptionClick = (index: number) => {
        const selectedOption = options[index];
        console.log(`Option clicked: ${selectedOption.label}`);

        // If logout is clicked, clear the localStorage and navigate to login
        if (selectedOption.action === 'logout') {
            console.log("Logging out...");
            localStorage.removeItem('isLoggedIn');
            navigate('/');
        } else {
            // Log other actions for now
            console.log(`Executing action: ${selectedOption.action}`);
        }
    };

    return (
        <div id="main-menu" className="main-menu">
            {/* Logo and Welcome Message */}
            <img id="title-logo" src={`${process.env.PUBLIC_URL}/gamelogo.png`} alt="Pok�Battle Generations" />
            <div id="welcome-message">
                <h1>Welcome to Pok�Battle Generations!</h1>
                <p>
                    Experience the thrill of battling Pok�mon from different generations. Choose your Pok�mon and challenge opponents in exciting battles!
                </p>
            </div>

            {/* Selection Menu */}
            <div id="button-container">
                <div id="selection-menu">
                    <img id="selection-ui" src={`${process.env.PUBLIC_URL}/BattleSelectionUI.png`} alt="Selection UI" />
                    <div id="battle-selection">
                        {options.map((option, index) => (
                            <div
                                key={index}
                                className={`menu-option`}
                                onClick={() => handleOptionClick(index)}
                            >
                                {option.label}
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* Optional Change Log */}
            <div id="changelog">
                <h2>Change Log</h2>
                <div id="changelog-content">
                    <p><strong>Version beta-0.1</strong> - Initial beta release with basic Generation 1 Pok�mon selection and battle functionality.</p>
                    <p><strong>Version beta-0.2</strong> - Added move functionality, improved battle mechanics, and fixed various bugs.</p>
                    <p><strong>Version beta-0.3</strong> - Introduced Pok�mon stats and levels, added Generation 2 Pok�mon, and implemented health bars.</p>
                    <p><strong>Version beta-0.4</strong> - Enhanced user interface with dynamic battle animations, added Generation 3 Pok�mon, and integrated multi-generation rival feature.</p>
                    <p><strong>Version beta-0.5</strong> - Implemented generation-specific battle themes, added an information tooltip for multi-generation rivals, and improved overall game performance and stability.</p>
                    <p><strong>Version beta-0.6</strong> - Improved the main menu design, adjusted layout for better readability, and optimized loading times.</p>
                    <p><strong>Version beta-0.7</strong> - Added support for multiple save files, enhanced Pok�mon AI behavior, and fixed various minor bugs.</p>
                    <p><strong>Version beta-0.8</strong> - Implemented new sound effects and background music, improved animation smoothness, and updated Pok�mon sprites.</p>
                    <p><strong>Version beta-0.9</strong> - Added Generation 4 Pok�mon, introduced special battle events, and implemented a new achievement system.</p>
                    <p><strong>Version beta-1.0</strong> - Enhanced battle strategy options, added new moves and abilities, and improved game stability.</p>
                    <p><strong>Version beta-1.1</strong> - Updated user profile interface, added social sharing options, and fixed critical bugs related to battle mechanics.</p>
                    <p><strong>Version beta-1.2</strong> - Introduced Generation 5 Pok�mon, added daily login rewards, and enhanced the overall game balance.</p>
                    <p><strong>Version beta-1.3</strong> - Improved performance on mobile devices, added cloud save functionality, and fixed various UI issues.</p>
                </div>
            </div>
        </div>
    );
};

export default MainMenu;
