import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../styles/login-register.css';

const LoginRegister: React.FC = () => {
    const [isRegistering, setIsRegistering] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [email, setEmail] = useState(''); // New email state
    const [isLoading, setIsLoading] = useState(true);

    const BACKEND_URL = 'https://pokemon-game-backend-production.up.railway.app';
    const API_KEY = process.env.REACT_APP_API_KEY;

    useEffect(() => {
        const loginStatus = localStorage.getItem('isLoggedIn');
        if (loginStatus === 'true') {
            window.location.href = '/menu';
        } else {
            setIsLoading(false);
        }
    }, []);

    const toggleForm = () => {
        setIsRegistering(!isRegistering);
        clearForm();
    };

    const clearForm = () => {
        setUsername('');
        setPassword('');
        setConfirmPassword('');
        setEmail(''); // Clear email field
    };

    const showNotification = (message: string, type: 'success' | 'error') => {
        const notification = document.createElement('div');
        notification.classList.add('notification', type);
        notification.textContent = message;
        document.body.appendChild(notification);

        setTimeout(() => {
            notification.classList.add('show');
        }, 100);

        setTimeout(() => {
            notification.classList.remove('show');
            setTimeout(() => {
                notification.remove();
            }, 300);
        }, 3000);
    };

    const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (!username || !password) {
            showNotification('Username and Password are required!', 'error');
            return;
        }

        try {
            const response = await axios.post(`${BACKEND_URL}/login`, { username, password }, {
                headers: { 'x-api-key': API_KEY }
            });

            if (response.data.success) {
                localStorage.setItem('isLoggedIn', 'true');
                clearForm();
                window.location.href = '/menu';
            } else {
                showNotification('Login failed: Invalid username or password', 'error');
            }
        } catch (error) {
            showNotification('Error during login. Please try again.', 'error');
        }
    };

    const handleRegister = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (!username || !password || !email) {
            showNotification('Username, password, and email are required!', 'error');
            return;
        }

        if (!confirmPassword) {
            showNotification('Please confirm your password!', 'error');
            return;
        }

        if (password !== confirmPassword) {
            showNotification('Passwords do not match!', 'error');
            return;
        }

        try {
            const response = await axios.post(`${BACKEND_URL}/register`, { username, password, email }, {
                headers: { 'x-api-key': API_KEY }
            });

            if (response.data.success) {
                showNotification('Registration successful', 'success');
                clearForm();
                setIsRegistering(false);
            } else {
                showNotification('Registration failed: User already exists', 'error');
            }
        } catch (error) {
            showNotification('Error during registration. Please try again.', 'error');
        }
    };

    if (isLoading) {
        return (
            <div className="loader-container">
                <div className="loader"></div>
            </div>
        );
    }

    return (
        <div className="login-register-screen">
            <div className="login-register-container">
                <div className="login-register-box">
                    <h2>{isRegistering ? 'Register' : 'Login'}</h2>
                    {isRegistering ? (
                        <form onSubmit={handleRegister} noValidate>
                            <input
                                type="text"
                                name="username"
                                placeholder="Username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                autoComplete="username"
                            />
                            <input
                                type="email"  // Email input
                                name="email"
                                placeholder="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                autoComplete="email"
                            />
                            <input
                                type="password"
                                name="password"
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                autoComplete="new-password"
                            />
                            <input
                                type="password"
                                name="confirm-password"
                                placeholder="Confirm Password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                autoComplete="new-password"
                            />
                            <div className="age-confirmation">
                                By registering, you confirm you are 13+ years of age or older.
                            </div>
                            <div className="login-register-buttons">
                                <button type="submit">Register</button>
                                <button type="button" onClick={toggleForm}>Back to Login</button>
                            </div>
                        </form>
                    ) : (
                        <form onSubmit={handleLogin} noValidate>
                            <input
                                type="text"
                                name="username"
                                placeholder="Username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                autoComplete="username"
                            />
                            <input
                                type="password"
                                name="password"
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                autoComplete="current-password"
                            />
                            <div className="forgot-password">
                                <a href="/password-reset">Forgot your Password?</a>
                            </div>
                            <div className="login-register-buttons">
                                <button type="submit">Login</button>
                                <button type="button" onClick={toggleForm}>Register</button>
                            </div>
                        </form>
                    )}
                </div>
            </div>
        </div>
    );
};

export default LoginRegister;
