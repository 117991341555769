import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';

// Ensure the DOM element with id="root" exists in index.html
const container = document.getElementById('root');
if (container) {
    const root = createRoot(container); // createRoot is used for React 18
    root.render(
        <React.StrictMode>
            <App />
        </React.StrictMode>
    );
} else {
    console.error('Root container not found.');
}
